import React, { useState } from 'react'
import './home.css'
import Slider from '../slider'
import LiListItems from '../../globalComponent/li'
import BlogDisplay from '../blogDisplay'
import DisplayDetails from '../displayDetail'
import ModalComponent from '../modal'
const HomeContainer = () => {

    const [showModal, setModal] = useState(false)
    const [blogModal, setBlogModal] = useState(false)
    const [cta_Tab, setCTAab] = useState()
    const [amountLoan, setAmountLoan] = useState()
    const [monthsLoan, setMonthLoan] = useState()
    const [interest, setInterestLoan] = useState()
    const [finalLoan, setLoan] = useState()
    const [monthlyInterestLoan, setmnthInterLoan] = useState()
    const [monthlyPayment, setMonthPayment] = useState()
    const [sipAmount, setSipAmount] = useState()
    const [sipRate, setSipRate] = useState()
    const [sipTime, setSipTime] = useState()
    const [sipFinalInvest, setsipFinalInvest] = useState()
    const [sipFutureAmount, setSipFutureAmount] = useState()
    const [blogContent, setBlogContent] = useState('Hello')


    const blogModalOpener = () => {
        setBlogModal(true)
    }
    const closeHandler = () => {
        setModal(false);
        setBlogModal(false)
        setAmountLoan();
        setMonthLoan();
        setInterestLoan();
        setLoan();
        setmnthInterLoan();
        setMonthPayment();
        setSipAmount()
        setSipRate()
        setSipTime()
        setsipFinalInvest()
        setSipFutureAmount()
    }
    const openHandler = (i) => {
        setModal(true)
        setCTAab(i)
        console.log(i);
        console.log('State' + i);
    }

    const leftMenu = [
        {
            id: '1',
            item: 'Website Designing',
            link: 'https://www.webmarcon.com'
        },
        {
            id: '2',
            item: 'Website Development',
            link: 'https://www.webmarcon.com'
        }, {
            id: '3',
            item: 'SEO',
            link: 'https://www.webmarcon.com'
        }, {
            id: '4',
            item: 'Digital Marketing',
            link: 'https://www.webmarcon.com'
        },
        {
            id: '5',
            item: 'Graphic Designing',
            link: 'https://www.webmarcon.com'
        },
        {
            id: '6',
            item: 'Video Editing',
            link: 'https://www.webmarcon.com'
        },
        {
            id: '7',
            item: 'Bussiness Consultation',
            link: 'https://www.webmarcon.com'
        },
        {
            id: '8',
            item: 'Ad Campaign',
            link: 'https://www.webmarcon.com'
        }
    ]
    const calculateLoan = () => {


        let monthlyPayment = ((amountLoan / monthsLoan) + interest);
        let totalInterest = (amountLoan * (interest * 0.01)) / monthsLoan;
        let totalPayment = parseFloat(amountLoan) + parseFloat(totalInterest);


        setLoan(parseFloat(totalPayment).toFixed(2))
        setMonthPayment(parseFloat(monthlyPayment).toFixed(2))
        setmnthInterLoan(parseFloat(totalInterest).toFixed(2));
    }
    function calculateSIP() {
        var monthlyRate = sipRate / 12 / 100;
        var months = sipTime * 12;
        var futureValue = 0;
        setSipFutureAmount(sipAmount * (Math.pow(1 + monthlyRate, months) - 1) / monthlyRate);
        setsipFinalInvest(sipAmount * months);
    }

    const CTA = [
        {
            id: '1',
            item: 'SIP Calculator',
            link: 'https://www.webmarcon.com',
            cta_content: <div className='sipCalculation' style={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1>SIP Calculator</h1>
                <input type="number" style={{
                    width: '80%',
                    height: '30px',
                    padding: '20px',
                    fontSize: "15px",
                    border: '1px solid #c0c0c0',
                    borderRadius: '15px',
                    margin: '5px 0px'
                }} placeholder='Invested Amount' onChange={(e) => setSipAmount(e.target.value)} />
                <input type="number" style={{
                    width: '80%',
                    height: '30px',
                    padding: '20px',
                    fontSize: "15px",
                    border: '1px solid #c0c0c0',
                    borderRadius: '15px',
                    margin: '5px 0px'
                }} placeholder='Return Rate %' onChange={(e) => setSipRate(e.target.value)} />
                <input type="number" style={{
                    width: '80%',
                    height: '30px',
                    padding: '20px',
                    fontSize: "15px",
                    border: '1px solid #c0c0c0',
                    borderRadius: '15px',
                    margin: '5px 0px'
                }} placeholder='Time Duration in Years' onChange={(e) => setSipTime(e.target.value)} />
                {
                    sipAmount && sipRate && sipTime && <>
                        <h4>Amount Invested : {sipFinalInvest}</h4>
                        <h4>Final Amount : {sipFutureAmount}</h4>
                    </>
                }

                <button onClick={calculateSIP} style={{ padding: '10px', margin: '10px 0px', borderRadius: '15px', background: '#fff', }}>Calculate SIP</button>
            </div>
        },
        {
            id: '2',
            item: 'Loan Calculator',
            link: 'https://www.webmarcon.com',
            cta_content: <div style={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2>Loan Calculator</h2>
                <input type="number" placeholder='Enter Loan Amount' style={{
                    width: '80%',
                    height: '30px',
                    padding: '20px',
                    fontSize: "15px",
                    border: '1px solid #c0c0c0',
                    borderRadius: '15px',
                    margin: '5px 0px'
                }} name="" id="" onChange={(e) => setAmountLoan(e.target.value)} className="amount" />
                <input type="number" placeholder='Enter Interest Rate ' style={{
                    width: '80%',
                    height: '30px',
                    padding: '20px',
                    fontSize: "15px",
                    border: '1px solid #c0c0c0',
                    borderRadius: '15px',
                    margin: '5px 0px'
                }} name="" id="" onChange={(e) => setInterestLoan(e.target.value)} className="interest" />
                <input type="number" placeholder='Enter Duration in Months' style={{
                    width: '80%',
                    height: '30px',
                    padding: '20px',
                    fontSize: "15px",
                    border: '1px solid #c0c0c0',
                    borderRadius: '15px',
                    margin: '5px 0px'
                }} name="" id="" onChange={(e) => setMonthLoan(e.target.value)} className="months" />

                {
                    amountLoan && monthsLoan && interest && <>
                        <h4>Loan : {finalLoan}</h4>
                        <h4>Interest : {monthlyInterestLoan}</h4>
                        <h4>Monthly : {monthlyPayment}</h4></>
                }

                <button onClick={calculateLoan} style={{ padding: '10px', margin: '10px 0px', borderRadius: '15px', background: '#fff', }}>Calculate Loan</button>
            </div>

        },
        {
            id: '3',
            item: 'Domain Check',
            link: 'https://www.webmarcon.com',
            cta_content: <>Domain Check</>

        },
        {
            id: '4',
            item: 'Company Name Check',
            link: 'https://www.webmarcon.com',
            cta_content: <>Company Name Check</>

        }
    ]


    return (
        <div className="homecontainer">
            {showModal ? <ModalComponent closeModal={closeHandler}  >{CTA[cta_Tab].cta_content} </ModalComponent> : null}
            {blogModal ? <ModalComponent closeModal={closeHandler}  >{blogContent} </ModalComponent> : null}
            <div className='homecontainerchild' style={{ background: 'red' }}>
                <LiListItems heading={true} headingText='Menu' link={true} list={leftMenu} />
            </div>
            <div className='homecontainerchild'><Slider />
                <DisplayDetails />
            </div>
            <div className='homecontainerchild'>
                <LiListItems heading={false} headingText='Menu' modalSetting={openHandler} link={false} isModal={true} list={CTA} />
                <BlogDisplay modalSetting={blogModalOpener} componentData={setBlogContent} />
            </div>
        </div>
    )
}

export default HomeContainer
