import React from 'react'
import SignIn from '../auth/signin'

const Login = () => {
    return (
        <div className='auth-container'>
            <SignIn />
        </div>
    )
}

export default Login
