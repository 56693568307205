import { createSlice } from '@reduxjs/toolkit';

const initialState = [

];

const slideShowSlice = createSlice({
    name: "slides",
    initialState,
    reducers: {
        addSlide: (state, action) => {
            state.push(action.payload);
        },
        removeSlide: (state, action) => {
            const slideIdToRemove = action.payload;
            return state.filter(slide => slide.id !== slideIdToRemove);
        },
        updateSlide: (state, action) => {
            const { id, updatedSlide } = action.payload;
            const existingSlide = state.find(slide => slide.id === id);
            if (existingSlide) {
                Object.assign(existingSlide, updatedSlide);
            }
        },
    }
});

export const { addSlide, removeSlide, updateSlide } = slideShowSlice.actions;

export default slideShowSlice.reducer;
