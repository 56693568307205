import React from 'react';
import { Link } from 'react-router-dom';

const LiListItems = ({ heading, headingText, list, modalSetting, isModal, link }) => {

    const handleModalOpening = (i) => {
        if (isModal) {
            return modalSetting(i);
        } else {
            return null;
        }
    };

    return (
        <div className='ul-li'>
            {heading ? (
                <p className="ul-heading">
                    {headingText}
                </p>
            ) : (
                <></>
            )}
            <ul>
                {list.map((li, index) => (
                    <li key={li.id} className='li--child' onClick={() => handleModalOpening(index)}>
                        {link ? (
                            <Link to={li.link}>
                                {li.item}
                            </Link>
                        ) : (
                            <span>{li.item}</span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LiListItems;
