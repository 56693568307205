import React from 'react'
import Hamburger from './hamburger'
import logo from '../asset/logo.png'

const NavbarMobile = () => {
    return (
        <div>
            <nav id='mobile-nav'>
                <Hamburger />
                <img src={logo} alt="" />
                <Hamburger />
            </nav>
        </div>
    )
}

export default NavbarMobile
