import React from 'react'
import './admin.css'
import MarqueeAdmin from './component/marquee'
import AdminTabs from './component/adminTabs'
const AdminHome = () => {
    return (
        <div className='adminPageContainer'>
            <MarqueeAdmin text='Welcome to the Webmarcon Finance Admin Page Where you gain the power to control!' />
            <AdminTabs />
        </div>
    )
}

export default AdminHome
