import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './routes/home';
import Register from './routes/register';
import Login from './routes/login';
import AdminHome from './admin/adminHome';
import AdminProtect from './adminProtect';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './configs/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { updateProfile } from './store/slices/profile';

const App = () => {
  const profileAuth = useSelector((state) => state.profile.isAuthenticated)
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState(null); // Changed state variable name to setEmailId and initialized as null

  useEffect(() => {
    const fetchData = async () => {
      onAuthStateChanged(auth, (au) => {
        if (au) {
          dispatch(updateProfile({ isAuthenticated: true, email: au.email }));
          setEmailId(au.email);
        } else {
          dispatch(updateProfile({ isAuthenticated: false }));
        }
      })

    };

    fetchData(); // Call the fetchData function directly, no need for await here

    return () => {
      // Cleanup function
    };
  }, [dispatch]);



  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='register' element={<Register />} />
      <Route path='login' element={<Login />} />

      <Route path='/' element={<AdminProtect />}>
        <Route path='admin' element={<AdminHome />} />
      </Route>
    </Routes>
  );
};

export default App;
