import React from 'react';
import './component.css';
import { useSelector } from 'react-redux';

const DisplayDetails = () => {
    // Fetch currentTab from Redux state
    const currentTab = useSelector((state) => state.tab.currentTab);

    // Define data based on the currentTab
    const dataForTabs = [
        {
            heading: 'Creative Ideas That Will Help Your Brand Soar',
            content: 'One of the top digital marketing agencies in Kolkata, Webmarcon, can create a comprehensive plan to advertise your company online. Professional with a dash of extra originality, we guarantee complete customer pleasure to assist you in expanding your clientele while keeping your current customers.'
        },
        {
            heading: 'Services',
            content: 'Content for Tab 2'
        },
        {
            heading: 'Contact Us',
            content: 'Content for Tab 3'
        }
        // Add more data objects for additional tabs if needed
    ];

    // Extract data for the current tab
    const currentTabData = dataForTabs[currentTab];

    return (
        <div className='displaydetails'>
            <div className='headingDisplayDetails'>
                {currentTabData.heading}
            </div>
            <div className='descDisplayDetails' style={{ textAlign: 'center' }}>

                {currentTabData.content}
            </div>
        </div>
    );
};

export default DisplayDetails;
