import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './auth.css';
import { auth } from '../configs/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSignUp = () => {
        // Check if passwords match
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        // Attempt to create user with provided email and password
        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                // Clear fields
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                // Redirect to home page
                navigate('/');
            })
            .catch((error) => {
                const errorMessage = error.message;
                // Set error state
                setError(errorMessage);
            });
    };

    return (
        <div className='signUp-Component'>
            <h1 style={{ textAlign: 'center' }}>Sign Up</h1>
            <input type="text" className='input-Text-Up-Register-Login' placeholder='Enter Your Name' />
            <input type="text" className='input-Text-Up-Register-Login' onChange={(e) => setEmail(e.target.value)} placeholder='Enter Your Email Address' />
            <input type="password" className='input-Text-Up-Register-Login' onChange={(e) => setPassword(e.target.value)} placeholder='Enter a Strong Password' />
            <input type="password" className='input-Text-Up-Register-Login' onChange={(e) => setConfirmPassword(e.target.value)} placeholder='Re-Type Your Password' />
            {error && <p className="error-message">{error}</p>}
            <button className='submit--auth' onClick={handleSignUp}>Submit</button>
        </div>
    );
};

export default SignUp;
