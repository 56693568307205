import { createSlice } from '@reduxjs/toolkit'

const profileSlice = createSlice({
    name: 'Profile',
    initialState: {
        isAuthenticated: Boolean,
        displayName: '',
        email: '',
        profilePicture: ''
    },
    reducers: {
        updateProfile: (state, action) => {
            const { isAuthenticated, displayName, email } = action.payload;
            if (isAuthenticated !== undefined) {
                state.isAuthenticated = isAuthenticated;
            }
            if (displayName !== undefined) {
                state.displayName = displayName;
            }
            if (email !== undefined) {
                state.email = email;
            }
        },
    }

})

export const { updateProfile } = profileSlice.actions
export default profileSlice.reducer