import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const AdminProtect = () => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated);

    return (
        isAuthenticated ? <Outlet /> : <Navigate to='/login' />
    );
};

export default AdminProtect;
