import React from 'react'

const MarqueeAdmin = ({ text }) => {
    return (
        <marquee behavior="" direction="" className='marquee-Finance'>
            {text}
        </marquee>
    )
}

export default MarqueeAdmin
