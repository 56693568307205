import React from 'react'
import './modal.css'

const ModalComponent = ({ children, closeModal }) => {

    const handleClose = () => {
        closeModal();
    }

    const handleContentClick = (event) => {
        event.stopPropagation();
    }

    return (
        <>
            <div className='modalContainer' onClick={handleClose}>

            </div>
            <div className="modalContent" onClick={handleContentClick}>
                {children}
            </div>
        </>
    );
}

export default ModalComponent;
