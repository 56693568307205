import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentTab: 0
}

const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        setCurrentTab(state, action) {
            state.currentTab = action.payload;
        }
    }
})

export const { setCurrentTab } = tabSlice.actions;

export default tabSlice.reducer;
