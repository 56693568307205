import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../globalComponent/navbar';
import NavbarMobile from '../globalComponent/navbarMobile';
import HomeContainer from '../components/HomeComponents/homeContainer';

const Home = () => {
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        // Function to check if the device is a mobile device
        const checkIfMobile = () => {
            const isMobileDevice = window.innerWidth <= 768; // You can adjust the threshold for what is considered a mobile device
            setMobile(isMobileDevice);
        };

        // Call the function initially
        checkIfMobile();

        // Add event listener to check for changes in window width
        window.addEventListener('resize', checkIfMobile);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    return (
        <>
            {isMobile ? <NavbarMobile /> : <Navbar />}
            {/* Use the appropriate Navbar component based on the isMobile state */}
            <HomeContainer ></HomeContainer>
            <Outlet />
        </>
    );
};

export default Home;
