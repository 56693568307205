import React, { useEffect, useState } from 'react';
import './blogs.css';
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../configs/firebase';
import parse from 'html-react-parser';

const BlogDisplay = ({ modalSetting, componentData }) => {
    const [blogs, setBlogs] = useState([]);
    const [currentBlogID, setBlogID] = useState();







    const getBlogs = () => {
        const blogRef = collection(db, 'blogs');
        onSnapshot(blogRef, (snapshot) => {
            const blogArr = [];
            snapshot.forEach((blog) => {
                const blogData = {
                    id: blog.id,
                    ...blog.data()
                };
                blogArr.push(blogData);
            });
            setBlogs(blogArr);
        });
    };
    const findBlogById = (id) => {
        return blogs.filter(blog => blog.id === id)[0]; // Using [0] to access the first element of the filtered array
    }



    useEffect(() => {
        getBlogs();
    }, []);


    const openHandlerBlog = async (id) => {
        setBlogID(id);
        const foundBlog = findBlogById(id); // Find the blog data based on the id
        console.log(foundBlog); // Logging the found blog data
        componentData(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <p style={{ fontWeight: '500', fontFamily: 'roboto', fontSize: '2rem' }}>{foundBlog.blogTitle}</p>
                <img style={{
                    height: '200px',
                    objectFit: 'cover'
                }} src={foundBlog.featuredImage} alt="" />
                <div>{foundBlog && parse(foundBlog.blogDescription)}</div>

            </div>
        )
        await modalSetting();
        setBlogID(null);
    };

    // Function to truncate the description
    const truncateDescription = (description, maxLength) => {
        if (description.length > maxLength) {
            return description.substring(0, maxLength) + '...';
        } else {
            return description;
        }
    };

    return (
        <div className='blogDisplay--main'>
            <p className="blogDisplayHeading">Blog</p>
            <div className="postDiv">
                {blogs.map((post) => (
                    <div className="blog-single-post" key={post.id} onClick={() => openHandlerBlog(post.id)}>
                        <img src={post.featuredImage} alt="" className='imgBlogFeature' />
                        <div className="post-details">
                            <p className="postTitle">{post.blogTitle}</p>
                            <p className="postDescription">{truncateDescription(parse(post.blogDescription), 60)}</p>
                            {/* Adjust the second argument (50) to change the maximum length of the excerpt */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogDisplay;
