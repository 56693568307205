import React from 'react'
import SignUp from '../auth/signup'
import '../routes.css'
const Register = () => {
    return (
        <div className='auth-container'>
            <SignUp />
        </div>
    )
}

export default Register
