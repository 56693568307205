import React from 'react'
import { FaBars } from "react-icons/fa";

const Hamburger = () => {
    return (
        <button id='hamburger-mobile'><FaBars /></button>
    )
}

export default Hamburger
