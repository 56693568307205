import React, { useEffect, useState } from 'react';
import './component.css';
import finance1 from '../asset/slider/finance1.jpg';
import finance2 from '../asset/slider/finance2.jpg';

const Slider = () => {
    const [counter, setCounter] = useState(0);
    const [wrapperHeight, setWrapperHeight] = useState('auto'); // Default to auto height
    const slider = [
        {
            id: 'finance1',
            img: finance1,
            alt: 'Financial Analysis Slide 1'
        },
        {
            id: 'finance2',
            img: finance2,
            alt: 'Financial Analysis Slide 2'
        }
    ];

    useEffect(() => {
        const slideImg = document.querySelectorAll('.slide');
        const maxHeight = Math.max(...Array.from(slideImg).map(img => img.clientHeight));
        setWrapperHeight(maxHeight + 'px');

        slideImg.forEach((slidex, index) => {
            slidex.style.left = `${index * 100}%`;
        });
    }, []);

    const slideFunction = () => {
        const slideImg = document.querySelectorAll('.slide');
        slideImg.forEach((slidex, index) => {
            slidex.style.transition = 'transform 0.5s ease-in-out';
            slidex.style.transform = `translateX(-${counter * 100}%)`;
        });
    };

    const prevImg = () => {
        setCounter(counter === slider.length - 1 ? 0 : counter - 1);
        slideFunction();
    };

    const nextImg = () => {
        setCounter(counter === slider.length - 1 ? 0 : counter + 1);
        slideFunction();
    };

    return (
        <div className='slider-wrapper-main'>
            <div className='slider--wrapper' style={{ height: wrapperHeight }}>
                {slider.map((slide) => (
                    <img src={slide.img} alt={slide.alt} className='slide' key={slide.id} />
                ))}
            </div>
            <button id='nextImg' onClick={nextImg}>Next</button>
            <button id='prevImg' onClick={prevImg}>Prev</button>
        </div>
    );
};

export default Slider;
