import { createSlice } from '@reduxjs/toolkit';

const initialState = [
    {
        id: null,
        blogTitle: "",
        blogDescription: "",
        blogPostDate: "",
        featuredImage: "",
    }
];

const BlogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        addReduxBlog: (state, action) => {
            state.push(action.payload);
        },
        updateBlog: (state, action) => {
            const { id, updatedBlog } = action.payload;
            const existingBlog = state.find(blog => blog.id === id);
            if (existingBlog) {
                Object.assign(existingBlog, updatedBlog);
            }
        },
        deleteBlog: (state, action) => {
            const id = action.payload;
            return state.filter(blog => blog.id !== id);
        },
    }
});

export const { addReduxBlog, updateBlog, deleteBlog } = BlogSlice.actions;

export default BlogSlice.reducer;
