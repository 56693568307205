// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage'
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDE1w8vO2egCed00_1WRUOTkwWW9PjNooE",
    authDomain: "webmarcon-finance-react.firebaseapp.com",
    projectId: "webmarcon-finance-react",
    storageBucket: "webmarcon-finance-react.appspot.com",
    messagingSenderId: "682736517808",
    appId: "1:682736517808:web:f0afba7fd9e441255df175"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const imgDb = getStorage(app)
export const db = getFirestore(app);