import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';
import { imgDb, db } from '../../configs/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, doc } from 'firebase/firestore';
import { addDoc, deleteDoc } from 'firebase/firestore';


const AdminTabs = () => {
    const [currentTab, setTab] = useState(0);
    const [imagePreview, setImagePreview] = useState('');
    const [file, setFile] = useState(null)
    const [img, setImg] = useState(null)
    const editor = useRef(null);
    const [blogTitle, setBlogTitle] = useState(''); // State for blog title
    const [content, setContent] = useState('');
    const [progress, setProgress] = useState(null);
    const profilePic = useSelector((state) => state.profile.profilePicture);
    const [deleteId, setDeleteId] = useState()
    const dispatch = useDispatch()

    const handleImageChange = (event) => {
        setFile(event.target.files[0]);
        // const reader = new FileReader();

        // reader.onload = function (e) {
        //     setImagePreview(e.target.result);
        // };
        // setImg(event.target.files)
        // reader.readAsDataURL(file);
    };






    const uploadFile = () => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(imgDb, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    setProgress(progress);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    reject(error);
                },
                async () => {
                    try {
                        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                        console.log(downloadUrl);
                        resolve(downloadUrl);
                    } catch (error) {
                        reject(error);
                    }
                }
            );
        });
    };

    const submitBlog = async () => {
        try {
            // Upload the file and wait for the downloadUrl to be obtained
            const downloadUrl = await uploadFile();

            // Add blog to Firestore
            const blogRef = collection(db, "blogs");
            await addDoc(blogRef, {
                blogTitle: blogTitle,
                blogDescription: content,
                featuredImage: downloadUrl,
            });
            console.log({
                blogTitle: blogTitle,
                blogDescription: content,
                featuredImage: downloadUrl,
            });

        } catch (error) {
            console.log("Error submitting blog:", error);
        }
    };
    const deleteBlog = async () => {
        await deleteDoc(doc(db, "blogs", `${deleteId}`));
    }






    const tabs = [
        {
            label: 'Upload Blog',
            content: <><h1>Upload Blog</h1>
                <div className="inputStore">
                    <label htmlFor="titleForBlog">
                        <p>Blog</p>
                        <input type="text" name="" id="" onChange={(e) => setBlogTitle(e.target.value)} />
                    </label>
                    <label htmlFor="description">
                        <p>Description</p>
                        <JoditEditor
                            style={{ height: '700px' }}
                            ref={editor}
                            value={content}
                            // config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={newContent => { }}
                        />

                    </label>
                    <div className="imageFileUpload">
                        <input type="file" name="fileInput" id="fileInput" onChange={handleImageChange} accept="image/*" />
                        <div className="preview-input-file">
                            {imagePreview && <img src={imagePreview} id='imgPreviewHere' alt="Image Preview" />}
                        </div>
                    </div>
                    <button className='saveUploadBlog' onClick={submitBlog}>Save</button>
                </div></>
        },

        {
            label: 'Delete Blog',
            content: <><h1>Delete Blog</h1>
                <div className="inputStore">
                    <label htmlFor="titleForBlog">
                        <p>Enter Blog ID</p>
                        <input type="text" name="" id="" onChange={(e) => setDeleteId(e.target.value)} />
                    </label>

                    <button className='delteConfirm' style={{
                        alignSelf: 'end',
                        borderRadius: '15px', fontSize: '1rem',
                        padding: '10px'
                    }} onClick={() => deleteBlog()}>Confirm Delete</button>
                </div></>
        },
        {
            label: 'Add Slider',
            content: <h1>Add Slider</h1>
        }, {
            label: 'Delete Slider',
            content: <h1>Delete Slider</h1>
        }
        // Add more tabs as needed
    ];

    return (
        <div className='adminTabs'>
            <div className="tabsChangerWrap">
                <div className="profileInfo">
                    <img src={profilePic} alt="" style={{ height: '80px', width: '80px', borderRadius: '50%', padding: '5px', objectFit: 'cover', border: "4px solid var(--primary-color)" }} />
                    <p>Profile Name</p>
                </div>
                {/* Buttons generated from tabs array */}
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={currentTab === index ? 'activeTab' : ''}
                        onClick={() => setTab(index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tabsContentWrap">
                {tabs[currentTab].content}
            </div>
        </div>
    );
};

export default AdminTabs;
