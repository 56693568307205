import React, { useEffect, useState } from 'react';
import './auth.css';
import { auth } from '../configs/firebase';
import { useNavigate } from "react-router-dom"
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate("/")
            }
        })
    }, [])

    const handleSignIn = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            // Signed in 
            // Clear the form fields after successful sign-in
            setEmail('');
            setPassword('');
            navigate('/')
        } catch (error) {
            // Handle sign-in error
            const errorMessage = error.message;
            setError(errorMessage);
        }
    };

    return (
        <div className='signUp-Component'>
            <h1 style={{ textAlign: 'center' }}>Sign In</h1>
            <input type="text" className='input-Text-Up-Register-Login' onChange={(e) => setEmail(e.target.value)} value={email} placeholder='Enter Your Email Address' />
            <input type="password" className='input-Text-Up-Register-Login' onChange={(e) => setPassword(e.target.value)} value={password} placeholder='Enter a Strong Password' />
            {error && <p className="error-message">{error}</p>}
            <button className='submit--auth' onClick={handleSignIn}>Submit</button>
        </div>
    );
}

export default SignIn;
