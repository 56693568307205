import React, { useState } from 'react';
import '../nav.css';
import logo from '../asset/logo.png';
import { useDispatch } from 'react-redux'
import { setCurrentTab } from '../store/slices/homeTab';
const Navbar = () => {
    const [activeIndex, setActiveIndex] = useState(0); // State to store the index of the active li
    const dispatch = useDispatch()
    const handleItemClick = (index) => {
        setActiveIndex(index); // Update the active index when a li is clicked
        dispatch(setCurrentTab(index))
    };

    return (
        <div className='navbarDesk'>
            <nav>
                <div className="nav--child">
                    <img src={logo} alt="" />
                </div>
                <div className="nav--child" id='navchild--list'>
                    <div className="marquee-text">
                        <marquee behavior="" direction="left">"Webmarcon is one of the most growing digital agency"</marquee>
                    </div>
                    <ul className="nav-child--children">
                        <li className={activeIndex === 0 ? 'active' : ''} onClick={() => handleItemClick(0)}>
                            About Us
                        </li>
                        <li className={activeIndex === 1 ? 'active' : ''} onClick={() => handleItemClick(1)}>
                            Services
                        </li>
                        <li className={activeIndex === 2 ? 'active' : ''} onClick={() => handleItemClick(2)}>
                            Contact Us
                        </li>
                    </ul>
                </div>
                <div className="nav--child" id='callToAction'>
                    <p>Call To Action</p>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
