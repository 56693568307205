import { configureStore } from '@reduxjs/toolkit'
import profileSlice from './slices/profile'
import BlogSlice from './slices/blogs'
import slideShowSlice from './slices/sliders'
import tabCount from './slices/homeTab'

export const store = configureStore({
    reducer: {
        profile: profileSlice,
        blog: BlogSlice,
        slides: slideShowSlice,
        tab: tabCount
    }
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())